html,body,#root{
  width:100%;
  height:100%;
  margin:0;
  padding:0;
}

// @import "~bootstrap/scss/functions";



// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";
// $primary: red;

@import "~bootstrap/scss/bootstrap";

.bg-purple{
  background-color: $purple-100;
}
